<template>
  <esmp-modal
    class="add-popular-modal"
    v-model="showed"
    width="800"
    :title="modalTitle"
    :closable="false"
    :loading="loading"
    :mask-closable="false"
  >
    <esmp-select
      v-if="!isEditMode"
      class="add-popular-modal__search"
      ref="search"
      placeholder="Поиск по популярным услугам"
      :remote-method="debouncedSearchMethod"
      filterable
      :loading="loading"
      clearable
      @on-select="onSelectService"
      @on-query-change="onQueryChange"
      @on-clear="clearQuery"
    >
      <esmp-select-option
        v-for="r in searchResults"
        :key="r.id"
        :value="r.id"
      >
        {{ r.name }}
      </esmp-select-option>
    </esmp-select>
    <subscription-list
      v-model="subscriptions"
      :service-id="selectedServiceId"
      @on-delete="deleteSubscription"
    />
    <template #footer>
      <esmp-button @click="addPopularService" :disabled="!selectedServiceId">
        Сохранить
      </esmp-button>
      <esmp-button view="outline" @click="closeAddPopularModal">
        Отмена
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
import debounce from 'lodash/debounce';
import { MIN_QUERY_LENGTH, SEARCH_DELAY } from '@/constants/search';
import SubscriptionList from '@/pages/admin/forms/components/SubscriptionList.vue';

export default {
  name: 'AddPopularModal',
  components: {
    SubscriptionList,
  },
  props: {
    showed: {
      type: Boolean,
      default: false,
    },
    editedPopularService: {
      type: Object,
      default: null,
    },
    subscriptionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchResults: [],
      showSearchResults: false,
      selectedServiceId: null,
      query: '',
      loading: false,
      subscriptions: [],
    };
  },
  computed: {
    isEditMode() {
      return this.editedPopularService;
    },
    modalTitle() {
      if (this.isEditMode) {
        return 'Изменить подписки популярной услуги';
      }
      return 'Добавить популярную услугу';
    },
  },
  watch: {
    showed(val) {
      if (!val) {
        this.clearQuery();
      }
    },
    editedPopularService(val) {
      if (val) {
        this.selectedServiceId = val.id;
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = [...val];
      }
    },
  },
  methods: {
    searchServices(query) {
      if (query?.length >= MIN_QUERY_LENGTH) {
        this.loading = true;
        this.$API.services.simpleSearch(query)
          .then(({ data }) => {
            this.searchResults = data;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.showSearchResults = false;
        this.searchResults = [];
      }
    },
    onSelectService(service) {
      this.selectedServiceId = service.value;
    },
    onQueryChange(query) {
      this.query = query;
    },
    clearQuery() {
      if (this.$refs.search) {
        this.$refs.search.setQuery(null);
      }
      this.query = '';
      this.searchResults = [];
      this.showSearchResults = true;
      this.selectedServiceId = null;
      this.subscriptions = [];
    },
    addPopularService() {
      this.$emit(
        this.isEditMode ? 'update-subscriptions' : 'add-popular-service',
        this.selectedServiceId,
        this.subscriptions,
      );
    },
    closeAddPopularModal() {
      this.clearQuery();
      this.$emit('close-add-popular-modal');
    },
    deleteSubscription(index) {
      this.subscriptions = this.subscriptions.filter((s, i) => i !== index);
    },
  },
  created() {
    this.debouncedSearchMethod = debounce(this.searchServices, SEARCH_DELAY);
  },
};
</script>

<style lang="scss">
.add-popular-modal {
  &__search {
    margin-bottom: $base-gutter;
  }
}
</style>
